import http from "../http";

//获取企业列表
export const getCompanyList = params => {
  return http({
    method: "get",
    url: `/api/user/slaveenterprises`,
    params
  });
};

//获取店铺列表
export const getStoreList = (params, parent) => {
  let config = {
    method: "get"
  };

  if (parent) {
    config.url = "/api/shop/customslaveenterprisestorelist";
    config.params = params;
    console.log(config);
    return http(config);
  } else {
    config.url = "/api/shop/storeList";
    config.params = params;
    //临时兼容
    config.params.ShopName = params.StoreName;
    console.log(config);
    //end
    return http(config);
  }
};

//获取交易类型列表
export const getTradeTypeList = params => {
  return http({
    method: "get",
    url: `/api/trade/multitradetypes`,
    params
  });
};

//数据大屏

//获取交易数据
export const getTradeData = params => {
  return http({
    method: "get",
    url: `/api/dataCompass/dataScreen`,
    params
  });
};

//取引日报
export const getTradeDailyList = params => {
  return http({
    method: "get",
    url: `/api/datacompass/dayreports`,
    params
  });
};

//取引月报
export const getTradeMonthlyList = params => {
  return http({
    method: "get",
    url: `/api/datacompass/monthreports`,
    params
  });
};
//日报下载
export const downloadDayReports = params => {
  return http({
    method: "get",
    url: `/api/datacompass/downloaddayreports`,
    params,
    responseType: "blob"
  });
};

//月报下载
export const downloadMonthReports = params => {
  return http({
    method: "get",
    url: `/api/datacompass/downloadmonthreports`,
    params,
    responseType: "blob"
  });
};
