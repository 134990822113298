<template>
  <div class="dataCompass">
    <div class="panel-title">
      {{ $t("dataScreenRouter") }}
    </div>
    <div class="dataScreen-box">
      <!-- 选择框栏 -->
      <div class="dataScreen-select-bar">
        <el-form label-position="top" :inline="true">
          <el-form-item v-if="companyList.length > 0">
            <!-- 子企业选择 -->
            <el-select
              v-model="searchForm.companyCode"
              filterable
              :loading="isLoadingCompanyList"
              @change="submitGetCompanyList"
              popper-class="dataScreen-select-item"
              value-key="enterpriseCode"
            >
              <el-option
                v-for="item in companyList"
                :key="item.enterpriseCode"
                :label="item.enterpriseName"
                :value="item.enterpriseCode"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <!-- 店铺选择 -->
            <el-select
              filterable
              :filter-method="filterStoreList"
              v-el-select-loadmore="loadmore"
              v-model="searchForm.storeId"
              @visible-change="onVisibleChangeStoreOption"
              @change="onChangeStore"
            >
              <el-option
                v-for="store in storeList"
                :key="store.storeId"
                :label="store.storeName"
                :value="store.storeId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchForm.dateRange">
              <el-option v-for="date in dateRangeList" :key="date.id" :label="date.name" :value="date.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="button">
            <el-button class="confirm" size="medium" @click="submitGetTradeData">{{ $t("tradeSearchBtn") }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 交易数据卡片 -->
      <div class="dataScreen-dataCard-box">
        <el-row :gutter="20">
          <el-col :xl="6" :lg="12" :md="12" :xs="24" :sm="12">
            <div class="dataScreen-dataCard-bg">
              <div
                class="dataScreen-dataCard-item dataScreen-dataCard-tradeAmount"
                :title="currencyType + statisticalData.tradeAmount"
              >
                <p class="dataScreen-dataCard-dataTitle">{{ $t("tradeAmountLabel") }}</p>
                <p class="dataScreen-dataCard-dataContent">
                  {{ moneyFormat(statisticalData.tradeAmount) }}
                </p>
              </div>
            </div>
          </el-col>
          <el-col :xl="6" :lg="12" :md="12" :xs="24" :sm="12">
            <div class="dataScreen-dataCard-bg">
              <div
                class="dataScreen-dataCard-item dataScreen-dataCard-tradeQuantity"
                :title="statisticalData.tradeQuantity"
              >
                <p class="dataScreen-dataCard-dataTitle">{{ $t("tradeQuantityLabel") }}</p>
                <p class="dataScreen-dataCard-dataContent">{{ statisticalData.tradeQuantity }}</p>
              </div>
            </div>
          </el-col>
          <el-col :xl="6" :lg="12" :md="12" :xs="24" :sm="12">
            <div class="dataScreen-dataCard-bg">
              <div
                class="dataScreen-dataCard-item dataScreen-dataCard-refundAmount"
                :title="currencyType + statisticalData.refundAmount"
              >
                <p class="dataScreen-dataCard-dataTitle">{{ $t("refundAmountLabel") }}</p>
                <p class="dataScreen-dataCard-dataContent">{{ moneyFormat(statisticalData.refundAmount) }}</p>
              </div>
            </div>
          </el-col>
          <el-col :xl="6" :lg="12" :md="12" :xs="24" :sm="12">
            <div class="dataScreen-dataCard-bg">
              <div
                class="dataScreen-dataCard-item dataScreen-dataCard-refundQuantity"
                :title="statisticalData.refundQuantity"
              >
                <p class="dataScreen-dataCard-dataTitle">{{ $t("refundQuantityLabel") }}</p>
                <p class="dataScreen-dataCard-dataContent">{{ statisticalData.refundQuantity }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 交易趋势图 -->
      <div class="dataScreen-trend-box" v-show="tradeHasValue">
        <div class="dataScreen-trend-title">{{ trendGraphTitle }}</div>
        <div id="trendGraphContainer" class="dataScreen-trend-graph"></div>
      </div>

      <div class="dataScreen-trend-box" v-show="!tradeHasValue">
        <div class="dataScreen-trend-title">{{ trendGraphTitle }}</div>
        <el-empty image="img/bg_empty.png" style="height:100%;"></el-empty>
      </div>
      <!-- 店铺占比 -->
      <div class="dataScreen-storeScale-box" v-show="storeScaleList.length > 0">
        <div class="dataScreen-trend-title">{{ $t("storeScaleTitle") }}</div>
        <!-- <div class="dataScreen-storeScale-graphT">{{ $t("storeScaleGraphTitle") }}</div> -->
        <div id="storeScaleGraphContainer" class="dataScreen-storeScale-graph"></div>
      </div>
      <div class="dataScreen-storeScale-box" v-show="storeScaleList.length <= 0">
        <!-- <div class="dataScreen-storeScale-title">{{ $t("storeScaleTitle") }}</div> -->
        <el-empty image="img/bg_empty.png"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
//import APP_CONFIG from "@/config";
import { DualAxes, Bar } from "@antv/g2plot";
import { getCompanyList, getStoreList, getTradeData } from "api/dataCompass";
import { moneyFormat, numFormat, pointFormat } from "utils";

export default {
  mixins: [],
  components: {},
  data() {
    return {
      tradeHasValue: false,
      updateTime: "2022-05-30 16:50:02",
      isShowQuantity: true,
      isShowAmount: true,
      trendGraphTitle: this.$t("trendGraphTitle0"),
      isLoaded: true,
      currencyType: this.$settings.currency == "JPY" ? "¥" : "$",
      isLoadingStoreList: false,
      isLoadingCompanyList: false,
      searchForm: {
        companyCode: "",
        storeId: "",
        dateRange: "0",
        comparePeriod: "0",
        filterStoreName: "",
        storePageIndex: 1,
        storeTotalPage: 5
      },
      companyList: [],
      storeList: [{ storeId: "", storeName: this.$t("allStoreLabel") }],
      dateRangeList: [
        {
          id: "0",
          name: this.$t("todayLabel")
        },
        {
          id: "1",
          name: this.$t("weekLabel")
        },
        {
          id: "2",
          name: this.$t("monthLabel")
        },
        {
          id: "3",
          name: this.$t("quarterLabel")
        },
        {
          id: "4",
          name: this.$t("yearLabel")
        }
      ],
      statisticalData: {
        tradeAmount: "",
        tradeQuantity: "",
        refundAmount: "",
        refundQuantity: ""
      },
      tradeAmountData: [],
      tradeQuantityData: [],
      storeScaleList: []
    };
  },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(".el-select-dropdown .el-select-dropdown__wrap");
        SELECTWRAP_DOM.addEventListener("scroll", function() {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      }
    }
  },
  mounted() {
    //获取企业&店铺列表

    getCompanyList().then(response => {
      if (response.statusCode == 200 && response.resultStatus == "Success") {
        this.companyList = response.result;
        if (this.companyList.length > 0) {
          this.companyList.unshift({ enterpriseCode: "", enterpriseName: this.$t("allCompanyLabel") });
        }
      }
    });

    /* 获取取引数据 */
    this.submitGetTradeData();
    /* 获取店铺列表 */
    this.submitGetStoreList(true);

    console.log("isMasterEnterprise?", this.$store.state.app.userInfo.isMasterEnterprise);
  },
  methods: {
    moneyFormat,
    submitGetTradeData() {
      let parames = {
        timeType: this.searchForm.dateRange,
        EntCode: this.searchForm.companyCode,
        ShopCode: this.searchForm.storeId
      };
      this.isLoaded = false;
      this.openFullScreen();
      getTradeData(parames).then(response => {
        this.isLoaded = true;
        if (response.statusCode == 200 && response.resultStatus == "Success") {
          this.trendGraphTitle = this.$t("trendGraphTitle" + this.searchForm.dateRange);
          this.statisticalData = response.result.screenCount;
          this.tradeAmountData = response.result.chartData.tradeAmountDatas;
          this.tradeQuantityData = response.result.chartData.tradeQuantityDatas;
          this.storeScaleList = response.result.shopDatas;
          this.tradeHasValue = response.result.chartData.hasValue;

          for (var i = 0; i < this.tradeAmountData.length; i++) {
            if (this.tradeAmountData[i].value) {
              this.tradeAmountData[i].value = this.tradeAmountData[i].value * 1;
            } else {
              this.tradeAmountData[i].value = 0;
            }
          }

          if (!this.dualAxes) {
            this.dualAxes = new DualAxes("trendGraphContainer", {
              data: [this.tradeAmountData, this.tradeQuantityData],
              xField: "time",
              yField: ["value", "count"],
              label: {
                position: "middle"
              },
              meta: {
                value: {
                  formatter: (text, index) => {
                    return moneyFormat(text);
                  }
                }
              },
              legend: {
                layout: "horizontal",
                position: "top",
                label: {
                  style: {
                    fontSize: 80
                  }
                }
              },
              geometryOptions: [
                {
                  geometry: "line",
                  seriesField: "name",
                  color: ["#ff8690", "#4c36ca", "#30ac94"]
                },
                {
                  geometry: "column",
                  columnWidthRatio: 0.4,
                  seriesField: "name",
                  isGroup: true,
                  color: ["#fda458", "#496def", "#30ac94"]
                }
              ]
            });
            this.dualAxes.render();
          } else {
            this.dualAxes.changeData([this.tradeAmountData, this.tradeQuantityData]);
          }
          if (this.storeScaleList.length > 0) {
            if (!this.barPlot) {
              this.barPlot = new Bar("storeScaleGraphContainer", {
                data: this.storeScaleList,
                xField: "sales",
                yField: "type",
                barWidthRatio: 0.3,
                height: 70 * this.storeScaleList.length + 10,
                // legend: {
                //   layout: "horizontal",
                //   position: "top",
                //   offsetY: 0,
                //   offsetX: -650,
                //   label: {
                //     style: {
                //       fontSize: 80
                //     }
                //   }
                // },
                autoRotate: true,

                label: {
                  position: "middle",
                  offsetY: -16,
                  offsetX: 50,
                  content: (text, item, index) => {
                    return moneyFormat(text.sales) + "    " + text.ratio + "%";
                  },
                  style: {
                    fill: "#000000",
                    fontSize: 14
                  },
                  adjustPosition: true
                },
                seriesField: "type"
              });

              this.barPlot.render();
            } else {
              this.barPlot.changeData(this.storeScaleList);
              this.barPlot.changeSize(this.barPlot.container.clientWidth, 70 * this.storeScaleList.length + 10);
              this.barPlot.update();
            }
          }
        }
      });
    },
    openFullScreen() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)"
      });
      let interval = setInterval(() => {
        if (this.isLoaded) {
          clearInterval(interval);
          loading.close();
        }
      }, 200);
    },
    submitGetCompanyList() {
      this.searchForm.storePageIndex = 1;
      this.searchForm.storeId = "";
      this.submitGetStoreList(true);
    },
    submitGetStoreList(isClear) {
      if (isClear) {
        this.searchForm.storePageIndex = 1;
      }
      const params = {
        EntList: [this.searchForm.companyCode],
        StoreName: this.searchForm.filterStoreName,
        PageIndex: this.searchForm.storePageIndex
      };
      getStoreList(params, this.$store.state.app.userInfo.isMasterEnterprise).then(response => {
        if (response.statusCode == 200 && response.resultStatus == "Success") {
          if (isClear) {
            this.storeList = [{ storeId: "", storeName: this.$t("allStoreLabel") }];
          }
          this.$nextTick(() => {
            this.storeList = this.storeList.concat(response.result);
            this.searchForm.storePageIndex += 1;
            this.searchForm.storeTotalPage = response.totalCount;
          });
        }
      });
    },
    filterStoreList(val) {
      this.searchForm.filterStoreName = val;
      this.searchForm.storePageIndex = 1;
      this.submitGetStoreList(true);
    },
    loadmore() {
      console.log(111);
      if (this.searchForm.storePageIndex > this.searchForm.storeTotalPage) {
        return false;
      } else {
        this.submitGetStoreList(false);
      }
    },
    onVisibleChangeStoreOption(val) {
      if (!val) {
        this.searchForm.filterStoreName = "";
        this.searchForm.storePageIndex = 1;
        this.submitGetStoreList(true);
      }
    },
    onChangeStore() {}
  }
};
</script>

<style scoped>
.dataScreen-box {
  /* min-width: 1300px; */
  margin-bottom: 22px;
}

.dataScreen-box > div {
  clear: both;
}

.dataScreen-select-bar {
  margin-top: 20px;
  clear: both;
}

.dataScreen-updateTime {
  float: right;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 1px;
}

.dataScreen-dataCard-box {
  height: 158px;
  width: 100%;
  /* min-width: 1200px; */
}

.dataScreen-dataCard-bg {
  height: 158px;
  min-width: 180px;
  background: #fff;
  border-radius: 10px;
  display: block;
  padding: 0;
  text-align: center;
  overflow: hidden;
  margin-bottom: 20px;
}

.dataScreen-dataCard-item {
  display: inline-block;
  background-image: url(~@/assets/dataScreen.png);
  background-repeat: no-repeat;
  padding-left: 103px;
  margin: 34px auto 0;
  text-align: left;
  min-height: 80px;
}

.dataScreen-dataCard-data {
  display: block;
}

.dataScreen-dataCard-tradeAmount {
  background-position: 0 0;
}

.dataScreen-dataCard-tradeQuantity {
  background-position: 0 -100px;
}

.dataScreen-dataCard-refundAmount {
  background-position: 0 -200px;
}

.dataScreen-dataCard-refundQuantity {
  background-position: 0 -300px;
}

.dataScreen-dataCard-dataTitle {
  height: 34px;
  line-height: 34px;
  color: #666666;
}

.dataScreen-dataCard-dataContent {
  font: 40px bold;
}

.dataScreen-trend-box {
  height: 582px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  /* margin-top: 22px; */
}

.dataScreen-trend-toolbar {
  height: 40px;
  padding: 30px 20px 0;
  line-height: 40px;
}

.dataScreen-trend-title {
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  padding-top: 30px;
}

.dataScreen-trend-graph {
  height: 440px;
  margin: 10px 35px;
}

.dataScreen-storeScale-box {
  background: #fff;
  border-radius: 10px;
  margin: 22px 0 0;
}

.dataScreen-storeScale-title {
  font-size: 20px;
  line-height: 40px;
  color: #4c36ca;
  text-indent: 40px;
  padding-top: 20px;
}

.dataScreen-storeScale-graphT {
  font-size: 20px;
  text-align: center;
  line-height: 30px;
}

.dataScreen-storeScale-graph {
  padding: 30px;
}

.dataScreen-storeRank-box {
  border-radius: 10px;
  background: #fff;
  margin-top: 22px;
  padding: 20px;
  color: #ff0000;
}
</style>
